<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
              <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Editar albarán
              </div>

              <!-- FORMULARIO ALBARAN -->
              <v-row class="my-2 mx-2">
                <v-col class="mb-n7" cols="6" sm="4" md="3" lg="3" xl="2">
                    <DateFind :readonly="true" label="Fecha" v-model="albaran.Fecha"></DateFind>
                </v-col>
              
                <v-col cols="12" sm="7" md="5" lg="5" xl="4">
                    <ProovedoresArtProvFind class="mb-n7" ref="provCtrl" 
                        v-model="albaran.ProveedorObj" label="Proveedor"
                        :dirId="this.dirId"
                        :readonly="true">
                    </ProovedoresArtProvFind>
                </v-col>
              </v-row>
              
              <v-row class="mt-1">
                <!-- LINEAS -->
                <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-row no-gutters>
                    <v-btn
                        :loading="loading"
                        :disabled="editNewRow || enviado"
                        @click="onBtnNuevaLinea"
                        color="primary"
                        small
                        class="mb-2 mr-2">
                        Nueva línea
                    </v-btn>

                    <v-spacer></v-spacer>
                    <!--
                    <v-switch class="mt-0 pt-0 pb-1"
                      v-model="filtrarCantidad"
                      :label="'Filtrar cantidad'"
                      :hide-details="true"
                      @change="mapLineas"
                    ></v-switch>-->
                  </v-row>
                  
                  <!-- :server-items-length="lineasTotalDataRows" -->
                  <v-data-table
                    :headers="tableHeaders"
                    :items="lineasDataRows"
                    :options.sync="lineasOptions"
                    :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                    :loading="loading"
                    @click:row="onClickRowEditarLinea"
                    style="overflow: scroll"
                    mobile-breakpoint="0"
                    class="elevation-1"
                    dense
                    >
                   
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.Cantidad="{item}">
                        <div v-if="item.edit && !enviado" class="my-1">
                          <v-text-field
                            class="mb-n7 no-arrows" outlined dense
                            type="number" min="0"
                            v-model="selectedRow.Cantidad"
                            label="Cantidad"
                            @blur="onBlurFixCantidad()"
                            @focus="$event.target.select()"
                          >
                            <v-icon class="mb-n1" slot="append" @click.stop="changeCantidad(selectedRow.Cantidad)">mdi-plus</v-icon>
                            <v-icon class="mb-n1" slot="prepend" @click.stop="changeCantidad(selectedRow.Cantidad, -1)">mdi-minus</v-icon>
                          </v-text-field>
                        </div>
                        <template v-else>
                          {{item.Cantidad.toFixed(2)}}
                        </template>
                      </template>
                      <!-- <template v-slot:item.FactorCompra="{item}">
                        <span v-if="item.edit && !enviado"><template v-if="selectedRow.Art!=null">{{selectedRow.Art.FactorCompra}}</template></span>
                        <span v-else>{{getFactor(item.ArticuloId)}}</span>
                      </template> -->
                      <!-- eslint-disable-next-line vue/valid-v-slot -->
                      <template v-slot:item.Opt="{ item }">
                        <div v-if="item.edit && !enviado" style="min-width: 150px">
                          <!-- <v-btn
                            :loading="loading"
                            @click.stop="onBtnSaveEditLinea"
                            color="primary"
                            class="mt-2 mb-4 mr-2"
                            small>
                            Aceptar
                            </v-btn> -->
                            
                            <v-btn
                            @click.stop="onBtnCancelEditLinea"
                            class="mt-2 mb-4 mr-2"
                            small>
                            Cancelar
                            </v-btn>
                        </div>
                      </template>
                      <!-- NUEVA LINEA FORMULARIO -->
                      <template v-if="editNewRow && !enviado" slot="body.append">
                        <tr class="new-row">
                          
                            <td>
                              <ArticuloArtProvFind 
                                  class="mb-n7" v-model="newRow.Art" label="Artículo"
                                  :dirId="dirId"
                                  :proveedor="albaran.ProveedorObj.xproveedor_id"
                                  :familia="filtros.Familia != null ? filtros.Familia.familia : null"
                                  :subfamilia="filtros.Subfamilia != null ? filtros.Subfamilia.subfamilia : null">
                              </ArticuloArtProvFind>
                            </td>
                            <td>0</td>
                            <td>
                              <v-text-field
                                class="mb-n7 no-arrows" outlined dense
                                type="number" min="0"
                                v-model="newRow.Cantidad"
                                label="Cantidad"
                                @blur="onBlurFixCantidad"
                                @focus="$event.target.select()"
                              >
                                <v-icon class="mb-n1" slot="append" @click.stop="changeCantidad(newRow.Cantidad,1,true,true)">mdi-plus</v-icon>
                                <v-icon class="mb-n1" slot="prepend" @click.stop="changeCantidad(newRow.Cantidad,-1,true,true)">mdi-minus</v-icon>
                              </v-text-field>
                            </td>
                            <td style="min-width: 250px"  align ="right">
                              <v-btn
                                :loading="loading"
                                @click="onBtnSaveLinea"
                                color="primary"
                                class="mt-2 mb-4 mr-2"
                                small>
                                Aceptar
                                </v-btn>
                                
                                <v-btn
                                @click="onBtnCancelLinea"
                                class="mt-2 mb-4 mr-2"
                                small>
                                Cancelar
                                </v-btn>
                            </td>
                        </tr>
                      </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                    <v-text-field
                            class="mt-0 mb-n7"
                            outlined
                            dense
                            text
                            v-model="albaran.NumeroProveedor"
                            :rules="[v => !!v || 'Este campo es obligatorio']"
                            label="Numero Albaran Proveedor"
                      ></v-text-field>
                </v-col>
                <v-col  class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                    <DateFind label="Fecha Albaran Proveedor" v-model="albaran.FechaProveedor"></DateFind>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                    <v-textarea
                        class="mt-0 mb-n7" outlined auto-grow
                        :readonly="enviado"
                        v-model="albaran.Observaciones"
                        label="Observaciones">
                    </v-textarea>
                </v-col>
              </v-row>

              <!-- ACEPTAR / ENVIAR / CANCELAR / BORRAR -->
              <v-row justify="center">
                  <v-btn v-if="!enviado"
                  :loading="loading"
                  @click="onBtnSave"
                  color="primary"
                  class="mt-2 mb-4 mr-2"
                  style="width:100px">
                  Aceptar
                  </v-btn>

                  <v-btn v-if="albaran.AlbaranId != null && !enviado"
                  :loading="loading"
                  @click="onBtnEnviar"
                  color="primary"
                  class="mt-2 mb-4 mr-2"
                  style="width:100px">
                  Enviar
                  </v-btn>
                  
                  <v-btn 
                  class="mt-2 mb-4 mr-2"
                  style="width:100px"
                  @click="goToList">
                    <template v-if="lineasDataRows.length == 0">Cancelar</template>
                    <template v-else>Salir</template>
                  </v-btn>

                  <v-btn v-if="!enviado"
                  color="error"
                  class="mt-2 mb-4"
                  style="width:100px"
                  @click="onBtnShowDeleteModal()"
                  >
                  Borrar
                  </v-btn>

                  <!-- <v-btn class="mt-2 mb-4 ml-3"
                  @click="enviado = !enviado"
                  >
                  test readonly
                  </v-btn> -->
              </v-row>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Pedido -->
        <v-dialog
          v-model="showDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR ALBARAN
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar el albarán? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Modal error -->
        <v-dialog
          v-model="showErrorDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              ERROR
            </v-card-title>

            <v-card-text class="mt-2">
              {{errorMsg}}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="onBtnCloseError"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from "axios";
import DateFind from "../../components/DateFind.vue";
import ArticuloArtProvFind from "../../components/ArtProv/ArticuloArtProvFind.vue";
import ProovedoresArtProvFind from "../../components/ArtProv/ProovedoresArtProvFind.vue";
import { mapState } from "vuex";
export default ({
    components: {
        ArticuloArtProvFind, DateFind, ProovedoresArtProvFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz', 'dirId', 'perIdAux', 'esTiendaCliente']),
        isMartin: function () {
          return (this.empId == 8 || this.empId == 12 || this.empId == 15);
        },
        tableHeaders: function () {
          if (this.isMartin) {
            return this.lineasHeaders.concat(this.martinHeaders).concat(this.actionHeaders);
          }
          return this.lineasHeaders.concat(this.actionHeaders);
        }
    },
    data () {
        return {
            loading: false,
            isNew: true,
            enviado: false,
            lineEditAction: 'NONE',
            showDeleteDialog: false,
            showErrorDialog: false,
            errorMsg: '',
            filtros: {
                Familia: null,
                Subfamilia: null
            },
            albaran: {
                AlbaranId: null,
                Fecha: null,
                ProveedorObj: null,
                Observaciones: ''
            },
            filtrarCantidad: false,
            apiLineas: [],
            factores: [],
            // lineasTotalDataRows: 0,
            lineasDataRows: [],
            lineasOptions: {itemsPerPage: 100},
            lineasHeaders: [
                //{ text: 'Referencia Proveedor', width: 100, sortable: false, value: 'ReferenciaProv' },
                { text: 'Artículo', width: 300, sortable: false, value: 'Articulo' },
                { text: 'Cantidad albaran', width: 100, sortable: false, value: 'CantidadPedida' },
                { text: 'Cantidad recibida', width: 200, sortable: false, value: 'Cantidad' },
                { text: '', width: 50, sortable: false, value: 'Opt', align: 'end' }
            ],
            martinHeaders: [
                // { text: 'Factor compra', width: 100, sortable: false, value: 'FactorCompra' },
            ],
            actionHeaders: [
            ],
            selectedRow: null,
            editNewRow: false,
            newPedidoRow: {},
            selectedRowDelete: null
        }
    },
    mounted() {
        this.albaran.AlbaranId = this.$route.params.id;
        this.loadData();
    },
    methods: {
      goToList() {
        this.$router.push('/albaranes');
      },
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            AlbaranId: this.albaran.AlbaranId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/albaranes/get", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Albaran;
            // const factores = result.data.Factores; 
            setTimeout(() => {
                this.loading = false;
                resolve({
                  item, 
                  //factores
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al cargar el albarán.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        let lines;

        switch(this.lineEditAction) {
          case 'NEW': 
            lines = this.newRow;
            break;
          case 'EDIT':
            lines = this.selectedRow;
            break;
          case 'DELETE':
            lines = this.selectedRowDelete;
            break;
          default:
            lines = null;
            break;
        }
        
        return new Promise((resolve) => {
          const controllerParameters = {
            EmpId: this.empId,
            DirId: this.dirId,
            // PerId: this.esTiendaCliente ? this.perId : this.perIdAux,
            AlbaranId: this.albaran.AlbaranId,
            // Fecha: this.albaran.Fecha,
            // ProveedorId: this.albaran.ProveedorObj != null ? this.albaran.ProveedorObj.xproveedor_id : null,
            // Proveedor: this.albaran.ProveedorObj != null ? this.albaran.ProveedorObj.xnomabrev : null,
            Observaciones: this.albaran.Observaciones,
            NumeroProveedor: this.albaran.NumeroProveedor,
            FechaProveedor: this.albaran.FechaProveedor,
            Lineas: lines,
            LineEditAction: this.lineEditAction
          };

        //   if (this.lineEditAction === 'ALL_ARTS')
        //   {
        //     controllerParameters.Familia = this.filtros.Familia != null ? this.filtros.Familia.familia : null;
        //     controllerParameters.Subfamilia = this.filtros.Subfamilia != null ? this.filtros.Subfamilia.subfamilia : null;
        //   }

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/albaranes/save", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al guardar el albarán.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            AlbaranId: this.albaran.AlbaranId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/albaranes/delete", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const success = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  success
                })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al borrar el albarán.');
            }
          });
        })
      },
      sendFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
              EmpId: this.empId,
              DirId: this.dirId,
              PerId: this.esTiendaCliente ? this.perId : this.perIdAux,
              AlbaranId: this.albaran.AlbaranId,
              // Desarrollo: true
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/albaranes/send", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
          const item = result.data;

          setTimeout(() => {
            this.loading = false
            resolve({
              item
            })
          }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al enviar el albaran.');
            }
          });
        })
      },
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Albarán no encontrado.');
              this.goToList();
              return;
            }
            this.mapData(data);
            this.enviado = data.item.Enviado;
          });
      },
      saveData() {
        this.postDataToApi().then(data => {
          if(data.item.AlbaranId != null) {
              if(this.lineEditAction == 'NONE') {
                this.goToList();
              } else {
                switch(this.lineEditAction) {
                  case 'NEW': 
                    this.editNewRow = false;
                    break;
                  case 'DELETE':
                    this.showDeleteLineaDialog = false;
                    break;
                }
                this.mapData(data);
              }
          }
          else {
              this.errorMsg = data.item.Error;
              this.showErrorDialog = true;
          }
          
          this.lineEditAction = 'NONE';
        });
      },
      mapData(data) {
        this.albaran = data.item;
        // this.factores = data.factores;
        const date = new Date(data.item.Fecha);
        this.albaran.Fecha = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0')  + '-' + String(date.getDate()).padStart(2, '0');
        const dateProveedor = new Date(data.item.FechaProveedor)
        this.albaran.FechaProveedor = dateProveedor.getFullYear() + '-' + String(dateProveedor.getMonth() + 1).padStart(2, '0')  + '-' + String(dateProveedor.getDate()).padStart(2, '0');

        if (data.item.Pedido != null)
        {
          this.albaran.ProveedorObj = {
              xproveedor_id: data.item.Pedido.ProveedorId,
              xnomabrev: data.item.Pedido.Proveedor
          }
        }
        else
        {
          this.albaran.ProveedorObj = {
              xproveedor_id: data.item.ProveedorId,
              xnomabrev: data.item.Proveedor
          }
        }

        this.lineasDataRows = [];
        this.apiLineas = [];
        this.apiLineas = data.item.AlbaranLineas.map(i => {
          return {
            AlbaranLineaId: i.AlbaranLineaId,
            AlbaranId: i.AlbaranId,
            PedidoId: i.PedidoId,
            Linea: i.Linea,
            ArticuloId: i.PedidoLinea == null ? i.ArticuloId : i.PedidoLinea.ArticuloId,
            Articulo: i.PedidoLinea == null ? i.Articulo : i.PedidoLinea.Articulo,
            Cantidad: i.Cantidad,
            CantidadPedida: i.PedidoLinea == null ? 0 : i.PedidoLinea.Cantidad,
            ReferenciaProv: i.PedidoLinea == null ? 0 : i.PedidoLinea.ReferenciaProv,
            edit: false
          }
        });
        this.selectedRow = null;
        this.mapLineas();
      },
      mapLineas() {
        // Cancelamos la edición si se está editando alguna linea
        if(this.selectedRow != null) {
          this.apiLineas.filter(f => f.edit).forEach(i => {
            i.edit = false;
          });
          this.selectedRow = null;
        }

        // this.apiLineas.sort((a, b) => {
        //   if (a.Articulo < b.Articulo) {
        //     return -1;
        //   }
        //   if (a.Articulo > b.Articulo) {
        //     return 1;
        //   }
        //   return 0;
        // });

        if(this.filtrarCantidad) {
          this.lineasDataRows = this.apiLineas.filter(f => f.Cantidad != 0 || f.CantidadPedida !=0);
        } else {
          this.lineasDataRows = this.apiLineas;
        }
      },
    //   getFactor(item) {
    //     try {
    //       const factor = this.factores.find(f => f.xarticulo_id == item);
    //       return factor.FactorCompra;
    //     } catch {
    //       return '';
    //     }
    //   },
      // Crear o editar una linea del pedido
      onClickRowEditarLinea(item) {
        if(this.loading || item.edit || this.enviado) { return; }
        
        if(this.selectedRow != null) {
          this.onBtnSaveEditLinea();
          return;
          // this.lineasDataRows
          // .filter(f => f.edit).forEach(i => {
          //   i.edit = false;
          // });
        }
        
        item.edit = true;

        this.selectedRow = {
          AlbaranLineaId: item.AlbaranLineaId,
          AlbaranId: item.AlbaranId,
          PedidoId: item.PedidoId,
          Linea: item.Linea,
          Cantidad: item.Cantidad,
          Art: {
            xarticulo_id: item.ArticuloId,
            xdescripcion: item.Articulo,
          },
          PedidoLinea: {
            ReferenciaProv: item.ReferenciaProv
            // FactorCompra: this.getFactor(item.ArticuloId)
          }
        };
        this.$forceUpdate();
      },
      onBtnNuevaLinea() {
        if(this.enviado) { return; }
        this.newRow = {};
        this.editNewRow = true;
      },
      // Confirmación guardado
      onBtnSave() {
          if(this.enviado) { return; }
          this.saveData();
      },
      onBtnSaveLinea() {
          if(this.enviado) { return; }
          this.newRow.ArticuloId = this.newRow.Art != null ? this.newRow.Art.xarticulo_id : 0;
          this.newRow.Articulo =  this.newRow.Art != null ?  this.newRow.Art.xdescripcion : null;
          this.lineEditAction = 'NEW';
          this.saveData();
      },
      onBtnSaveEditLinea () {
        if(this.enviado) { return; }
        this.lineEditAction = 'EDIT';
        this.selectedRow.ArticuloId =  this.selectedRow.Art != null ? this.selectedRow.Art.xarticulo_id : 0;
        this.selectedRow.Articulo = this.selectedRow.Art != null ? this.selectedRow.Art.xdescripcion : null;
        this.saveData();
      },
      // Enviar
      onBtnEnviar() {
        if(this.enviado) { return; }
        this.sendFromApi()
        .then(data => {
          if(data.item.Error != null) { 
            this.errorMsg = data.item.Error;
            this.showErrorDialog = true;
          }
          else if(!data.item) { 
            this.errorMsg = 'Albarán no enviado';
            this.showErrorDialog = true;
          }
          else {
            // Marcar como enviado
            this.enviado = true;
            this.goToList();
          }
        });
      },
      // Confirmación de borrado
      onBtnDelete() {
        if(this.enviado) { return; }
        this.deleteDataFromApi().then(data => {
          this.showDeleteDialog = false;
          if (data.success.Success) {
            this.goToList();
          } else {
              this.errorMsg = data.success.Error;
              this.showErrorDialog = true;
          }
        });
      },
      // Cancelar edición de lineas
      onBtnCancelLinea() {
        this.editNewRow = false;
      },
      onBtnCancelEditLinea() {
        this.lineasDataRows
        .filter(f => f.edit).forEach(i => {
          i.edit = false;
        });
        this.selectedRow = null;
        this.$forceUpdate();
      },
      // Mostrar modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      // Cierre modales
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCloseError() {
        this.errorMsg = '',
        this.showErrorDialog = false;
      },
      // Eventos y validaciones
      onBlurFixCantidad() {
          if(this.newRow != null) { this.newRow.Cantidad = Number(this.newRow.Cantidad).toFixed(2); }
          if(this.selectedRow != null) { this.selectedRow.Cantidad = Number(this.selectedRow.Cantidad).toFixed(2); }
      },
      changeCantidad(item, factor = 1, isNew = false, forceUpdate = false)
      {
        const itemNumber = isNaN(item) ? 0 : Number(item);
        const result = itemNumber + factor;
        if(isNew) this.newRow.Cantidad = result;
        else this.selectedRow.Cantidad = result;
        this.onBlurFixCantidad(); 
        if (forceUpdate) this.$forceUpdate();
      }
    }
})
</script>

<style>
/* Chrome, Safari, Edge, Opera */
.no-arrows>div>div>div>input::-webkit-outer-spin-button,
.no-arrows>div>div>div>input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-arrows>div>div>div>input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
