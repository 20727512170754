<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Solicitud de vacaciones
            </div>

            <v-form :disabled="loading">
                <v-row class="my-2">
                    <v-col class="py-1" cols="12" sm="4" md="3" lg="3" xl="3">
                        <span>Días Solicitados: {{daysInfo.requested + selectedDaysOfYear() - daysInfo.deleted}}</span><br>
                        <span>Días Concedidos: {{daysInfo.accepted}}</span><br>
                        <!-- <span v-show="this.esPerOficina">Dias Pendientes: {{30 - (daysInfo.requested + selectedDaysOfYear() - daysInfo.deleted + daysInfo.accepted)}}</span><br> -->
                        <span v-show="this.esPerOficina">Dias Pendientes: {{(diasPendientesActual + diasPendientesAnterior) - (daysInfo.requested + selectedDaysOfYear() - daysInfo.deleted + daysInfo.accepted)}}</span><br>
                        <!-- <span v-show="this.esPerOficina">Días año actual: {{diasPendientesActual}}</span><br> -->
                        <!-- <span v-show="this.esPerOficina">Días año anterior: {{diasPendientesAnterior}}</span> -->

                      </v-col>
                    <v-col class="py-1" cols="12" sm="4" md="4" lg="4" xl="4">
                        <!-- <v-switch class="mt-0"
                        v-model="week"
                        :label="week ? 'Semana' : 'Día'"
                        :hide-details="true"
                        ></v-switch> -->
                        <v-btn-toggle mandatory color="primary" v-model="week">
                            <v-btn :value="false" small>
                                <span>Día</span>
                                <v-icon right>mdi-calendar-today</v-icon>
                            </v-btn>
                            <v-btn :value="true" small>
                                <span>Semana</span>
                                <v-icon right>mdi-calendar-week</v-icon>
                            </v-btn>
                        </v-btn-toggle>

                        <v-select v-if="empId == 8 || empId == 12" 
                            class="mt-3" dense outlined hide-details
                            v-model="opcNum"
                            :items="opcNumOptions"
                            item-value="value"
                            item-text="label"
                            label="Opción"
                            @change="resetEvents(false); loadData();">
                        </v-select>

                        <div>
                            <PeriodFind class="mt-3" ref="periodCtrl" 
                                v-model="period" 
                                label="Periodo"
                                :tipo="periodType"
                                @change="onChangePeriodo(); resetEvents();">
                            </PeriodFind>
                        </div>
                    </v-col>
                    <v-col class="py-1" cols="12" sm="4" md="5" lg="5" xl="5">
                        <span v-for="item in leyend" :key="item.type" class="d-inline-block mr-2">
                            <v-sheet width="15" height="15" class="leyend" style="border-color=black" :color="item.color"></v-sheet>
                            {{item.type}}
                        </span>
                    </v-col>
                </v-row>
            </v-form>

            <v-sheet tile height="50" :class="{'d-flex' : true, 'mb-n1' : loading}">
                <div class="mt-auto mb-2"><strong>{{getCalendarTitle()}}</strong></div>
                <v-spacer></v-spacer>
                <v-btn small outlined class="mt-3" @click="today">
                    Hoy
                </v-btn>
                <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-sheet>
            <v-row v-if="loading" class="my-0 px-3" justify="center">
                <v-progress-linear 
                indeterminate
                rounded
                ></v-progress-linear>
            </v-row>
            <v-sheet :height="calendarHeight" :class="{'calendar-loading': loading }">
                <v-calendar
                    class="vac-calendar"
                    ref="calendar"
                    v-model="calendarValue"
                    color="primary"
                    :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                    :type="'month'"
                    :events="events"
                    :event-overlap-mode="'column'"
                    :event-overlap-threshold="30"
                    :event-color="getEventColor"
                    @click:day="onClickSelectDay"
                    @click:date="onClickSelectDay"
                    @change="onChangeCalendar">
                    <template v-slot:day="{ date }">
                        <div v-if="getPeriodInfo(date) != null" class="mt-n11 fill-height special-day">
                            <v-sheet
                                :color="getEventColor(getPeriodInfo(date))"
                                width="100%"
                                height="100%"
                                tile>
                            </v-sheet>
                        </div>
                    </template>
                </v-calendar>
            </v-sheet>
            <v-sheet tile height="50" class="d-flex">
                <v-btn small color="primary" class="mt-2 mr-2" 
                    :loading="loading" 
                    :disabled="(selectedDays.length == 0 && deletedEvents.length == 0) || period == null || errorLoading" 
                    @click="onBtnClickSaveCalendar">
                    Aceptar
                </v-btn>
                <v-btn to="/" small class="mt-2 mr-2">
                    Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn small class="mt-2" @click="saving = false; checkIncidences(); showIncidenciasDialog = true;">
                    Incidencias
                </v-btn>
            </v-sheet>
        </v-container>

        <!-- Incidencias -->
        <v-dialog
            v-model="showIncidenciasDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    INCIDENCIAS
                </v-card-title>

                <v-card-text class="mt-2">
                    <v-list>
                        <v-list-item v-for="item in incidences" :key="item" class="pl-0">{{item}}</v-list-item>
                        <v-list-item v-if="incidences.length == 0" class="pl-0">No hay ninguna incidencia.</v-list-item>
                    </v-list>
                    <label v-if="saving">¿Deseas continuar con la solicitud?</label>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="saving" 
                    color="primary" 
                        :loading="loading" 
                        :disabled="(selectedDays.length === 0 && deletedEvents.length === 0) || period == null || errorLoading"
                        @click="saveCalendar">
                        Aceptar
                    </v-btn>
                    <v-btn class="ml-2" @click="showIncidenciasDialog = false;">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Días especiales -->
        <v-dialog
            v-model="showSpecialDaysDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    DÍAS ESPECIALES
                </v-card-title>

                <v-card-text class="mt-2">
                    <p>Uno de los días seleccionados se incluye en un periodo que no está permitido elegir vacaciones.</p>
                    <p>¿Deseas solicitarlo de todas formas?</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="selectSpecialDays(true)">
                        Aceptar
                    </v-btn>
                    <v-btn class="ml-2" @click="selectSpecialDays(false)">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Detalle solicitud -->
        <v-dialog
            v-model="showEventDetailDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    <template v-if="eventDetail == null">DETALLE DÍA</template>
                    <template v-else-if="eventDetail.type == statusRefs.aceptado">DÍA ACEPTADO</template>
                    <template v-else-if="eventDetail.type == statusRefs.denegado">DÍA DENEGADO</template>
                    <template v-else>DETALLE DÍA</template>
                </v-card-title>

                <v-card-text class="mt-2">
                    <template v-if="eventDetail != null">
                        <p>Fecha de solicitud: {{formatDate(eventDetail.vacStaFecSol, true)}}</p>
                        <p>Fechas solicitadas: 
                            <template v-if="formatDate(eventDetail.start) == formatDate(eventDetail.end)">
                                {{formatDate(eventDetail.start)}}
                            </template>
                            <template v-else>
                                {{formatDate(eventDetail.start)}} - {{formatDate(eventDetail.end)}}
                            </template>
                        </p>
                        <!-- Solicitado -->
                        <template v-if="eventDetail.type == statusRefs.solicitado">
                            <p v-if="perResp != null">A revisar por: {{perResp.PerNom}}</p>
                        </template>
                        <!-- Aceptado -->
                        <template v-else-if="eventDetail.type == statusRefs.aceptado">
                            <p>Fecha de aceptación: {{formatDate(eventDetail.vacStaFec, true)}}</p>
                            <p>Aceptado por: {{eventDetail.perNom}}</p>
                        </template>
                        <!-- Denegado -->
                        <template v-else-if="eventDetail.type == statusRefs.denegado">
                            <p>Fecha de denegación: {{formatDate(eventDetail.vacStaFec, true)}}</p>
                            <p>Denegado por: {{eventDetail.perNom}}</p>
                            <p style="white-space: pre-line">Motivo: {{eventDetail.vacStaDetObs}}</p>
                        </template>
                    </template>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="eventDetail != null && eventDetail.type == statusRefs.solicitado"
                        color="error" class="ml-2" 
                        @click="showEventDetailDialog = false; showCancelVacDialog = true;">
                        Cancelar solicitud
                    </v-btn>
                    <v-btn class="ml-2" @click="closeEventDetailDialog">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Cancelar solicitud -->
        <v-dialog
            v-model="showCancelVacDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    CANCELAR SOLICITUD
                </v-card-title>

                <v-card-text class="mt-2">
                    <p>La solicitud seleccionada se encuenta en proceso de validación. ¿Desea cancelarla?</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" class="ml-2" @click="onClickCancelVac">
                      Cancelar
                  </v-btn>
                  <v-btn class="ml-2" @click="closeCancelVacDialog">
                      Cerrar
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import PeriodFind from "../components/PeriodFind.vue";
export default ({
    components: {
      PeriodFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'esPerOficina', 'urlRaiz']),
        periodType: function () {
          return this.esPerOficina ? 1 : 0;
        },
    },
    data () {
        return {
            loading: false,
            errorLoading: false,
            showIncidenciasDialog: false,
            showSpecialDaysDialog: false,
            showEventDetailDialog: false,
            showCancelVacDialog: false,
            calendarHeight: '600',
            calendarValue: '',
            lastCalendarDate: null,
            perResp: null, // Superior de la persona que ve el calendario
            events: [], // Eventos a mostrar en el calendario
            apiEvents: [], // Eventos cargados de la API
            selectedDays: [], // Eventos de los días seleccionados por el usuario,
            selectedPeriods: [], // Periodo elegido por el usuario mapeado para marcar en el calendario
            specialPeriods: [], // Periodos especiales que no se pueden seleccionar
            holidayPeriods: [],
            deletedEvents: [],
            daysInfo: {
              requested: 0,
              accepted: 0,
              deleted: 0
            },
            week: false,
            opcNum: 1,
            opcNumOptions: [
              { label: 'PRIMERA OPCIÓN', value: 1 },
              { label: 'SEGUNDA OPCIÓN', value: 2 }
            ],
            period: null,
            leyend: [
                { code: 1, type: 'Solicitado', color: 'purple' },
                { code: 12, type: 'Aceptado', color: 'success' },
                { code: 13, type: 'Denegado', color: 'warning' },
                { code: 99, type: 'Especial', color: 'red lighten-2' },
                { code: -1, type: 'Seleccionado', color: 'info' },
                { code: -2, type: 'Periodo elegido', color: 'yellow lighten-5' }
            ],
            statusRefs: {
                solicitado: 1,
                aceptado: 12,
                aceptadoProv: 2,
                denegado: 13,
                denegadoProv: 3,
                especial: 99,
                seleccionado: -1,
                periodo: -2
            },
            // statusRelations: [
            //     { code: 12, provCode: 2 }, // Aceptado
            //     { code: 13, provCode: 3 }  // Denegado
            // ],
            eventDetail: null,
            incidences: [],
            saving: false,
            daysToSelect: [],
            diasPendientesActual: 0,
            diasPendientesAnterior: 0 // Días a elegir cuando aparece el dialogo de días especiales
        }
    },
    watch: {
      loading: {
        handler() {
          if (this.loading) {
            this.$refs.periodCtrl.$refs.PeriodFindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    created() {
      window.addEventListener('resize', this.setCalendarHeight);
    },
    destroyed() {
      window.removeEventListener('resize', this.setCalendarHeight);
    },
    mounted() {
      this.setCalendarHeight();
      this.today();
      this.lastCalendarDate = new Date(this.calendarValue);
      this.loadData();
      this.getPerSupFromApi().then(data => {
        this.perResp = data.item;
      });
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        // Obtenemos el primer día mostrado en el calendario
        const fechaDesde = new Date(this.$refs.calendar.lastStart.date);
        let fechaDesdeWeekday = this.$refs.calendar.lastStart.weekday;
        if (fechaDesdeWeekday == 0) { fechaDesdeWeekday = 7; }
        fechaDesde.setDate(fechaDesde.getDate() - (fechaDesdeWeekday - 1));

        // Obtenemos el último día mostrado en el calendario 
        const fechaHasta = new Date(this.$refs.calendar.lastEnd.date);
        const fechaHastaWeekday = this.$refs.calendar.lastEnd.weekday;
        // Si la empresa es Martin, cargamos 1 semana más
        // para gestionar los eventos al seleccionar dos semanas a la vez
        const daysToAdd = (this.empId == 8 || this.empId == 12) ? 7 : 0;
        fechaHasta.setDate(fechaHasta.getDate() + (7 - fechaHastaWeekday) + daysToAdd);

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST',
            EmpId: this.empId,
            PerId: this.perId,
            OpcNum: this.opcNum,
            FechaDesde: fechaDesde,
            FechaHasta: fechaHasta,
            Ano: new Date(this.calendarValue).getFullYear()
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/vac", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const daysData = result.data.DaysData;
            const specialPeriods = result.data.SpecialPeriods;
            const holidayPeriods = result.data.HolidayPeriods;
            const diasPendientesActual = result.data.DiasPendientes.VacActual;
            const diasPendientesAnterior = result.data.DiasPendientes.VacAnterior;

            this.diasPendientesActual = diasPendientesActual;
            this.diasPendientesAnterior = diasPendientesAnterior;

            setTimeout(() => {
              this.loading = false;
              this.errorLoading = false;
              resolve({
                items,
                daysData,
                specialPeriods,
                holidayPeriods,
                diasPendientesActual,
                diasPendientesAnterior
              })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                this.errorLoading = true;
                alert('Error al cargar las solicitudes.');
            }
          });
        })
      },
      getPerSupFromApi() {
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_PERSUP',
            PerId: this.perId,
            EmpId: this.empId,
            RespType: 'VAC'
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Resp;

            setTimeout(() => {
                resolve({
                  item
                })
            }, 1000)
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_LIST',
            PerId: this.perId,
            Dias: this.selectedDays.map(m => m.date.map(date => new Date(date))),
            VacDel: this.deletedEvents,
            OpcNum: this.opcNum,
            PeriodId: this.period != null ? this.period.PeriodId : null
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/vac", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const success = result.data.Success
            const message = result.data.Message

            setTimeout(() => {
              this.loading = false
              resolve({
                success,
                message
              })
            }, 1000)
          }).catch(error => {
            if (error != null) {
                this.loading = false;
                alert('Error al guardar las solicitudes.');
            }
          });
        })
      },
      onChangeCalendar() {
        const newCalendarDate = new Date(this.calendarValue);
        // Solo recargamos la información si se ha cambiado de mes
        if (this.lastCalendarDate != null && newCalendarDate.getMonth() != this.lastCalendarDate.getMonth()) {
          // Recargamos la información de los días si se ha cambiado de año
          if (newCalendarDate.getFullYear() != this.lastCalendarDate.getFullYear()) {
            this.daysInfo.requested = 0;
            this.daysInfo.accepted = 0;
          }
          this.loadData();
        }
        this.lastCalendarDate = newCalendarDate;
      },
      loadData() {
        this.getDataFromApi()
        .then(data => {
          // Mapeo de los eventos cargados desde la api
          this.apiEvents = [];

          data.items.forEach(vac => {
            const statusCode = vac.VacSta.VacStaId;
            // Comprobar si su estado es provisional
            // const statusRelationIndex = this.statusRelations.findIndex(f => f.provCode === statusCode);
            // if (statusRelationIndex !== -1) {
            //   statusCode = 1;
            // }
            const statusIndex = this.leyend.map(m => m.code).indexOf(statusCode);
            const statusName = statusIndex > -1 ? this.leyend[statusIndex].type : '';

            const days = this.getPeriodDays(new Date(vac.VacFecDes), new Date(vac.VacFecHas));

            const newEvent = {
              id: vac.VacId,
              name: statusName,
              date: days,
              start: new Date(days[0] + 'T00:00:00').getTime(),
              end: new Date(days[days.length-1] + 'T23:59:59').getTime(),
              timed: false,
              type: statusCode,
              vacStaDetObs: vac.VacSta.VacStaDetObs,
              perNom:  vac.VacSta.Per != null ? vac.VacSta.Per.PerNom : '',
              vacStaFec: vac.VacSta.VacStaFec,
              vacStaFecSol: vac.VacStaFecSol
            };
            this.apiEvents.push(newEvent);
          });

          // Mapeo de la información de los días
          this.daysInfo.requested = data.daysData.Solicitados;
          this.daysInfo.accepted = data.daysData.Aceptados;

          // Mapeo de los periodos especiales
          this.specialPeriods = [];
          data.specialPeriods.forEach(period => {
            const statusCode = 99;

            period.PeriodFec.forEach(periodFec => {
              const days = this.getPeriodDays(new Date(periodFec.FecDes), new Date(periodFec.FecHas));
              const newEvent = {
                date: days,
                type: statusCode
              };
              this.specialPeriods.push(newEvent);
            });
          });
          
          // Mapeo de periodos de vacaciones
          this.holidayPeriods = data.holidayPeriods;

          this.loadCalendarEvents();        
        });
      },
      loadCalendarEvents() {
        // Quitamos los eventos a eliminar
        const apiEvents = this.apiEvents.filter(f => this.deletedEvents.indexOf(f.id) == -1)

        // Unimos los datos cargados de la API con los dias seleccionados
        this.events = apiEvents.concat(this.selectedDays);
      },
      onClickSelectDay(event) {
        if (this.loading || this.errorLoading) { return; }

        const detailTypes = [
          this.statusRefs.solicitado,
          this.statusRefs.aceptado,
          this.statusRefs.denegado
        ];
        // Comprobamos si ha seleccionado un dia ocupado
        const detailEvent = this.apiEvents
          .filter(f => {
            return this.isDayOnRange(event.date, f.date) && 
              detailTypes.includes(f.type) &&
              this.deletedEvents.indexOf(f.id) === -1;
          });
        if (detailEvent[0] != null) {
          this.showEventDetailDialog = true;
          this.eventDetail = detailEvent[0];
          return;
        }

        if (this.period == null) {
          alert('Debes elegir un periodo antes de seleccionar los días.');
          return;
        }

        // Comprobamos que dias se tienen que seleccionar
        let days = [];
        if (this.week) {
            // Si es Martin Martin se seleccionan dos semanas
            const weeksToGet = (this.empId != 8 && this.empId != 12) ? 1 : 1;
            days = this.getWeekDays(new Date(event.date), weeksToGet);
        } else {
            days.push(event.date);
        }

        if (!this.checkPeriod(days)) {
          alert('Uno de los días seleccionados no pertenece al periodo ' + this.period.PeriodNom + '.');
          return;
        }

        // Comprobamos si ya hay algún evento en los días elegidos
        const eventsToCheck = this.apiEvents
          .filter(f => this.deletedEvents.indexOf(f.id) == -1)
          .filter(f => {
            return this.isDayRangeOnRange(days, f.date);
          });

        // Si hay eventos aceptados o cancelados (añadir mensaje)
        if (eventsToCheck.filter(f => f.type == this.statusRefs.aceptado || f.type == this.statusRefs.denegado).length > 0) { 
          alert('Uno de los días seleccionados pertenece a un periodo aceptado o rechazado y no se puede modificar.'); 
          return; 
        }

        // Si hay un día solicitado
        if (eventsToCheck.filter(f => f.type == this.statusRefs.solicitado).length > 0) { 
          eventsToCheck.filter(f => f.type == this.statusRefs.solicitado).forEach(event => {
            this.daysInfo.deleted += event.date.length;
          });

          this.deletedEvents = this.deletedEvents.concat(
            eventsToCheck.filter(f => f.type == this.statusRefs.solicitado).map(m => m.id)
          );

          this.selectDays(days, false);
          this.loadCalendarEvents();
          return;
        }

        // Si el día clickado ya está seleccionado se deseleccionaran los días
        const datesSelected = this.selectedDays.map(m => m.date);
        const isSelected = datesSelected.findIndex(dates => {
          return this.isDayRangeOnRange(days, dates);
        }) != -1;
        
        // Solo comprobamos los periodos especiales en el caso de marcar días
        if (!isSelected) {
          const hasSpecialDay = this.checkSpecialDays(days); 
          if (hasSpecialDay) {
            this.daysToSelect = days;
            this.showSpecialDaysDialog = true;
            return;
          }
        }

        this.selectDays(days, !isSelected);
        // Recargamos los eventos del calendario
        this.loadCalendarEvents();
      },
      onClickCancelVac() {
        this.deletedEvents.push(this.eventDetail.id);
        this.showCancelVacDialog = false;
        this.eventDetail = null;
        this.loadCalendarEvents();
      },
      checkPeriod(days) {
        if (this.period == null) { return false; }

        const daysOutOfRange = days.filter(f => {
          const date = new Date(f);
          date.setHours(0, 0, 0, 0);

          const periods = this.period.PeriodFec.filter(f2 => {
            const startDate = new Date(f2.FecDes);
            const endDate = new Date(f2.FecHas);
            return date >= startDate && date <= endDate;
          });
          return periods.length == 0;
        }).length;

        const isOnPeriod = daysOutOfRange == 0;
        return isOnPeriod;
      },
      checkSpecialDays(days) {
        // Comprobamos si alguno de los días está contenido en la lista de días especiales
        const specialPeriodIndex = this.specialPeriods.findIndex(period => {
            return this.isDayRangeOnRange(days, period.date);
        });
        const hasSpecialDay = specialPeriodIndex != -1;
        return hasSpecialDay;
      },
      selectSpecialDays(select) {
        // Si es false, se ha cancelado la selección
        if (select) {
          this.selectDays(this.daysToSelect, true);
          this.loadCalendarEvents();
        }

        this.daysToSelect = [];
        this.showSpecialDaysDialog = false;
      },
      selectDays(days, selected) {
        const selectedDates = this.selectedDays.map(m => m.date);
        const isOnRange = selectedDates.findIndex(dates => {
          return this.isDayRangeOnRange(days, dates);
        }) != -1;
        
        if (!isOnRange) {
            // Si no está en la lista y hay que eliminarlo, no hacemos nada
            if (!selected) { return; }
            // Si no está en la lista lo añadimos
            const newSelectedDay = {
                name: 'Seleccionado',
                date: days,
                start: new Date(days[0] + 'T00:00:00').getTime(),
                end: new Date(days[days.length-1] + 'T23:59:59').getTime(),
                type: -1, //'Seleccionado',
                timed: false
            };
            this.selectedDays.push(newSelectedDay);
        } else {
            // Si está en la lista y hay que añadirlo, no hacemos nada
            if (selected) { return; }
            // Si está en la lista lo eliminamos
            this.selectedDays = this.selectedDays.filter(f => {
              return !this.isDayRangeOnRange(days, f.date);
            });
        }
      },
      getWeekDays(date, weeksToGet) {
        let days = [];
        
        let weekday = date.getDay();
        if (weekday == 0) { weekday = 7; }

        const monday = new Date(date.setDate(date.getDate() - (weekday - 1)));
        const daysToGet = weeksToGet * 7;

        for(var i = 0; i < daysToGet; i++) {
            const nextDayTimestamp = new Date(monday).setDate(monday.getDate() + i);
            const nextDay = new Date(nextDayTimestamp);
            days.push(this.getDateString(nextDay));
        }

        return days;
      },
      getPeriodDays(startDate, endDate) {
        let days = [];

        let dateString = '';
        do {
          dateString = this.getDateString(startDate);
          days.push(dateString);
          startDate.setDate(startDate.getDate() + 1);
        } while (dateString != this.getDateString(endDate));

        return days;
      },
      isDayRangeOnRange(days, dayRange) {
        return dayRange.findIndex(day => {
          return this.isDayOnRange(day, days);
        }) != -1;
      },
      isDayOnRange(day, days) {
        return days.indexOf(day) != -1;
      },
      onBtnClickSaveCalendar() {
        this.checkIncidences();
        if (this.incidences.length > 0) {
          this.saving = true;
          this.showIncidenciasDialog = true;
        } else {
          this.saveCalendar();
        }
      },
      saveCalendar() {
        //#region Comprobaciones extra
        // Categoria test
        // const checkCategoria = this.$store.state.per.PerCatLevels.find(x=> x.PerCatId == 394) != null;
        // if (!checkCategoria)
        // {
        //   // Fecha
        //   const now = new Date(Date.now());
        //   const finFeb = new Date(2022, 1, 28); // 28/02/2022
        //   if (now < finFeb)
        //   {
        //     alert('La grabación de vacaciones todavía no está habilitada. Podrá hacerlo a partir del próximo día 28 de Febrero.')
        //     return;
        //   }
        // }
        // else 
        // {
        //   alert('Los datos grabados son sólo una prueba y se borrarán automáticamente cuando termine este periodo de testeo.');
        // }
        //#endregion

        this.postDataToApi()
        .then(data => {
          if (data.success == true) {
            this.showIncidenciasDialog = false;
            this.resetEvents(false);
            this.loadData();
          } else {
            alert(data.message);
          }
        });
      },
      onChangePeriodo() {
        this.selectedPeriods = [];
        if (this.period != null && this.period.PeriodFec != null && this.period.PeriodFec.length > 0) {
          const firstDate = new Date(this.period.PeriodFec[0].FecDes);
          this.calendarValue = this.getDateString(firstDate);
          
          this.period.PeriodFec.forEach(periodFec => {
            const days = this.getPeriodDays(new Date(periodFec.FecDes), new Date(periodFec.FecHas));
            const newEvent = {
              date: days,
              type: -2
            };
            this.selectedPeriods.push(newEvent);
          });
        }
      },
      resetEvents(reload = true) {
        this.selectedDays = [];
        this.deletedEvents = [];
        this.daysInfo.deleted = 0;
        if (reload) { this.loadCalendarEvents(); }
      },
      checkIncidences() {
        this.incidences = [];
        if (this.daysInfo.requested + this.selectedDaysOfYear() - this.daysInfo.deleted + this.daysInfo.accepted > 30) {
          this.incidences.push('Límite de 30 días de vacaciones anuales superado.');
        }
      },
      getEventColor (event) {
        const index = this.leyend.map(m => m.code).indexOf(event.type);
        if (index > -1) { 
          return this.leyend[index].color; 
        }
        return 'grey';
      },
      getPeriodInfo(date) {
        const periods = this.specialPeriods.concat(this.selectedPeriods);
        const index = periods.map(m => m.date).findIndex(i => {
          return i.indexOf(date) != -1;
        });
        if (index > -1) {
            return periods[index];
        } 
        return null;
      },
      setCalendarHeight() {
          this.calendarHeight = window.innerWidth >= 600 ? 600 : 450;
      },
      getDateString(date) {
        try {
            return date.getFullYear() + '-' + 
                String(date.getMonth() + 1).padStart(2, '0')  + '-' + 
                String(date.getDate()).padStart(2, '0');
        } catch {
            return '';
        }
      },
      today() {
        const today = new Date();
        this.calendarValue = this.getDateString(today);
      },
      getCalendarTitle () {
        const selectedDate = new Date(this.calendarValue);
        const monthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        return monthLabels[selectedDate.getMonth()] + ' ' + selectedDate.getFullYear();
      },
      formatDate(value, addTime = false) {
        const date = new Date(value);
        let dateString = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear();
        if (addTime) {
          dateString += ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
        }
        return dateString;
      },
      selectedDaysOfYear() {
        let totalDays = 0;
        const year = new Date(this.calendarValue).getFullYear();

        this.selectedDays.map(m => m.date).forEach(dates => {
          totalDays += dates
            .filter(f => new Date(f).getFullYear() == year)
            .filter(f => {
              var date = new Date(f + 'T00:00:00');
              var holiday = this.holidayPeriods
                .find(f2 => date >= new Date(f2.FecDes) && date <= new Date(f2.FecHas));
              return holiday == null; 
            })
            .length
        });

        return totalDays;
      },
      closeEventDetailDialog() {
        this.showEventDetailDialog = false;
      },
      closeCancelVacDialog() {
        this.showCancelVacDialog = false;
        this.eventDetail = null;
      }
    } 
})
</script>

<style>
  .leyend.red.lighten-2, .leyend.yellow.lighten-5 {
    border-color: black !important;
  }
  .v-sheet.leyend {
    border: 1px solid black !important;
    display: inline-block;
  }
  .vac-calendar .v-calendar-weekly__day {
    cursor: pointer;
  }
  .calendar-loading .vac-calendar.v-calendar .v-calendar-weekly__day {
    cursor: progress;
  }
  .vac-calendar .v-calendar-weekly__head-weekday {
    background-color: #E0E0E0 !important;
  }
  .vac-calendar .v-calendar-weekly__day-label span {
    text-transform: uppercase;
  }
  .vac-calendar .v-event {
    margin-top: 2px;
    /* position: absolute !important; */
  }
  .vac-calendar div.v-event-more + div.special-day {
      margin-top: -67px !important;
  }
</style>
