<template>
  <v-container>
    <!-- <div style="margin-top: 20px; margin-bottom: 20px; margin-left: auto; margin-right: auto; height: 200px; width: 80%; overflow-y: auto; overflow-x: auto; border-style: solid; border-width: 1px; padding-top: 10px; padding-left: 10px; padding-bottom: 10px; padding-right: 10px"> -->
    <!-- <v-card elevation="6" height="200px" style="margin-left: 20px; margin-right: 20px; margin-top: 20px; margin-bottom: 20px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; padding-right: 10px; overflow-y: auto"> -->
    
      
    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <h2 class="ml-4 mr-4 mb-0 font-weight-bold">{{cuestionario.Titulo}}</h2>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <img v-bind:src="'https://d2jnzwd77blap.cloudfront.net/wp-content/uploads/2020/06/05095235/logo_MartinMartin_web.png'" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <p class="ml-8 mr-8">Hola, <span style="font-weight: bold">{{ this.$store.state.per.PerNom }}</span></p>
      </v-col>
    </v-row>
   
    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-row dense>
          <p class="ml-8 mr-8" v-html="cuestionario.Descripcion" style="text-align: center"></p>
        </v-row>
      </v-col>
      
    </v-row>

    <v-row dense>
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-radio-group class="ml-8 mr-8 mt-n4" row dense v-model="respuestas.respuestaUsuario">
          <v-radio
            label="Dia 30 de 10:00 a 12:00"
            value="mañana"
          >
          </v-radio>
          <v-radio
            label="Dia 30 de 16:00 a 18:00"
            value="tarde"
          >
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <!-- </div> -->
    <br>
    <v-row dense >
      <v-col cols="auto" style="margin-left: auto; margin-right: auto">
        <v-btn
          color="primary"
          :loading="loading"
          @click="onClickSaveData"
        >
          Grabar inscripcion
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import axios from "axios"
  import { mapState } from "vuex";
  // import router from '../router'
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'perAux', 'esEmpleado', 'urlRaiz'])
    },
    data: () => ({
      loading: false,
      cuestionario: {
      Respuestas: []
    },
      
    horas:[],

    respuestas:{
      horarios: { PreguntasCuestionarioId: 25, respuestaUsuario:[] },
    }
    

     
      // mostrarAlergenos: false
    }),

    methods: {
      // validate () {
      //   this.$refs.form.validate()
      //   if (this.valid)
      //   {
      //       this.enviar();
      //   }
      // },
      getDataFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Titulo: 'REUNION LANZAMIENTO MM 2025'
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/get", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Cuestionario;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el cuestionario');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Respuestas: [ this.respuestas.horarios ],
            CuestionarioId: this.cuestionario.CuestionarioId,
            PerId: this.perId,
            DirId: 0
              //DirId: this.perAux != null && this.perAux.Dir != null && this.perAux.Dir.length > 0 ?
              //this.perAux.Dir[0].DirId : null
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/respuesta/save", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Success;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el cuestionario');
            }
          });
        })
      },
      loadData(){
        this.getDataFromApi()
          .then(data => {
            this.cuestionario = data.item;
          });
      },
      onClickSaveData() {
        if (!this.esEmpleado) {
          alert('INSCRIPCION NO GUARDADA CORRECTAMENTE - Estas intentando guardar la participación desde el usuario de la tienda, inicia sesión desde tu intranet PERSONAL para grabar la inscripción');
          this.$router.push('/');
          return;
        }

        this.respuestas.horarios.respuestaUsuario = this.respuestas.respuestaUsuario.toString();
        this.postDataToApi()
          .then(data => {
            const success = data.item;
            if (success)
            {
              alert('Muchas gracias por tu participación, se ha guardado correctamente');
              this.$router.push('/');
            }
          });
      },

    },
    mounted() {
      this.loadData();
    }
  }
</script>